.App {
  font-family: 'IBM Plex Sans', sans-serif;  
  font-size: calc(8px + 1vmin);
  display: flex;
  justify-content: center;
  /*background-color: #41256C;*/
}

/*.App-logo {
  height: 40vmin;
  pointer-events: none;
}
*/

.App-container {
  background-color: #41256C;
  min-height: 100vh;
  color: #fff;
  font-weight: 300;
  letter-spacing: 10;
  width: 480px;
  margin-top: 10vh;
}

.App-container h1 {
  font-size: 2vmin;
}

.App-container h2 {
  font-family: 'IBM Plex Mono', monospace;
  font-weight: 300;
  /*font-size: 14px;*/
  font-size: 1vmin;
  line-height: 2vmin;
  color: rgba(255,255,255,0.4);
  text-transform: uppercase;
}

.Title {
  margin-bottom: 2.5vh;
}

.setColorToggle {
  display: flex;
  justify-content: start;
  align-items: center;  
  /*padding: 10px 5px 10px 5px;*/
  padding-bottom: 10px;
  border-bottom:  1px solid rgba(255,255,255,0.4);
  margin-top: 1vh;
  width:  80%;
  height: 20px;
}

.active-color {
  width: 20px;
  height: 20px;
  background-color: #fff;
  display: inline-block;
  border-radius: 50%;
  cursor: pointer;
  margin-right: 10px;
}

.outline {
  fill:  none;
  border-radius: 0%;
  border: 1px solid rgba(255,255,255,0.5);
  background: none;  
}

.text-button {
  font-size: 14px;
  display: inline-block;
  /*margin-left: 10px;  */
  text-transform: uppercase;
  color: rgba(255,255,255,0.8);
  width:  30px;
}

.draw-menu {
  width:  480px;
  display: flex;
  justify-content: space-between;
  /*justify-content: center;*/
  align-items: center;
}

.downloadButton {
  font-size: 16px;
}

.button-row {
  display: flex;
  align-items: center;
}

.button-row > * {
  margin-left: 15px;
  cursor: pointer;
}

#logo {
  margin-top: 2.5vh;
/*  margin-bottom: 2.5vh;*/
}

.strong-serif {
  display: inline-block;
  font-family: 'IBM Plex Sans', sans-serif;  
  font-weight: 500;
}

.light-mono {
  display: inline-block;
  /*font-family: 'IBM Plex Mono', monospace;*/
  font-weight: 300;
}

.ControlSection {
  margin-top: 2.5vh;
  margin-bottom: 2.5vh;
}

.ControlSection h3 {
  text-transform: uppercase;
  font-weight: 300;
  font-size: 1vh;
  color:  rgba(255,255,255,0.65);
}

.OptionsRow {
  display: flex;
  /*align-items: center;*/
  /*justify-content: center;*/
  /*align-items: space-between;*/
}

.OptionsRow > * {
  width: 120px;
}

.ConfigOption {
  margin-top: 2vh;
  cursor: pointer;
}

.ConfigOption h4 {
  font-family: 'IBM Plex Mono', monospace;
  font-size: 1.2vmin;
}

.ConfigOption textarea {
  padding-left: 8px;
  background-color: transparent;
  
  border-width: 0px;
  border: 1px solid rgba(255,255,255,0.5);
  outline:  none;
  width:  40px;
  height: 20px;
  color:  #fff;
  margin-top: 1vh;
  font-size: 1.5vh;
  resize: none;
  rows: 0;
}

.RowSelection {
  display: flex;
  justify-content: space-between;
}

.BottomMenu {
  display: flex;
  justify-content: space-between;
}

.text-action {
  margin-bottom: 10px;
  font-size: 14px;
}
